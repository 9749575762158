// Routes
import { Routes } from "./routes/index.routes";
import "./index.css";
import { ThemeProvider } from "@mui/material";
import { GLTheme } from "gl-mui-theme";
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={GLTheme}>
        <Routes />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
