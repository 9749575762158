/* eslint-disable  */
import React from "react";
import { DevicesMapProps } from "../index";
import useSupercluster from "use-supercluster";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import { Marker, Popup, useMap } from "react-leaflet";
import { useCallback, useEffect, useState } from "react";
import { BBox } from "geojson";
import { PointFeature } from "supercluster";
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
type ClusterProperties = {
  cluster: boolean;
  deviceId: number;
  category: string;
};

type ClusterPoint = ClusterProperties & {
  type: "Feature";
  geometry: {
    type: "Point";
    coordinates: number[];
  };
};

type ShowDevicesProps = DevicesMapProps;

const icons: any = {};

const fetchIcon = (count: any, size: any) => {
  if (!icons[count]) {
    icons[count] = L.divIcon({
      html: `<div class="cluster-marker" style="width: ${size}px; height: ${size}px;">
        ${count}
      </div>`,
    });
  }
  return icons[count];
};
L.Marker.prototype.options.icon = DefaultIcon;

export const ShowDevices = ({ deviceList }: ShowDevicesProps) => {
  const maxZoom = 22;
  const [bounds, setBounds] = useState<BBox>([0, 0, 0, 0]);
  const [zoom, setZoom] = useState(12);
  const map = useMap();

  function updateMap() {
    const b = map.getBounds();
    setBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat,
    ]);
    setZoom(map.getZoom());
  }

  const onMove = useCallback(() => {
    updateMap();
  }, []);

  useEffect(() => {
    updateMap();
  }, []);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  useEffect(() => {
    function getCurrentPosition() {
      navigator.geolocation.getCurrentPosition((position) => {
        if (!position) return;

        const { latitude, longitude } = position.coords;

        map.flyTo([latitude, longitude], 12, {
          animate: true,
        });
      });
    }

    getCurrentPosition();
  }, [map]);

  const points: Array<PointFeature<ClusterPoint>> = deviceList
    ? deviceList.map(
        (device) =>
          ({
            type: "Feature",
            properties: {
              cluster: false,
              deviceId: device.device_id,
              category: device.device_measurement_category,
            },
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(device.loc_long.toFixed(6)),
                parseFloat(device.loc_lat.toFixed(6)),
              ],
            },
          } as PointFeature<ClusterPoint>)
      )
    : [];

  const { clusters, supercluster } = useSupercluster<any>({
    points: points || [],
    bounds,
    zoom: zoom,
    options: { radius: 75, maxZoom: 17 },
  });

  return (
    <>
      {clusters.map((cluster, index) => {
        // every cluster point has coordinates
        const [longitude, latitude] = cluster.geometry.coordinates;
        if (longitude === 0 && latitude === 0) return;
        // the point may be either a cluster or a crime point
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;

        // we have a cluster to render
        if (isCluster) {
          return (
            <Marker
              key={`cluster-${index}`}
              position={[latitude, longitude]}
              icon={fetchIcon(
                pointCount,
                10 + (pointCount / points!.length) * 40
              )}
              eventHandlers={{
                click: () => {
                  if (!supercluster) return;

                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(Number(cluster.id)),
                    maxZoom
                  );
                  map.setView([latitude, longitude], expansionZoom, {
                    animate: true,
                  });
                },
              }}
            />
          );
        }
        const { deviceId } = cluster.properties;
        // we have a single point (crime) to render
        return (
          <Marker
            key={`device-${cluster.properties.deviceId}-${index}`}
            position={[latitude, longitude]}
          >
            {deviceId && <Popup>Device Id: {deviceId}</Popup>}
          </Marker>
        );
      })}
    </>
  );
};
