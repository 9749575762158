import { Box } from "@mui/material";
import { GLFormTop, GLLoginForm } from "gl-mui-theme";
import { errorsLoginHookForm, useLogin } from "./useLogin";
import * as yup from "yup";

export const loginFormValidationSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});
export const Login = () => {
  const {
    loginErrorMessage,
    loginForm,
    handleChangeLoginForm,
    handleNavigateToPrivacyPolicy,
    handleNavigateToResetPassword,
    handleNavigateToTermsOfService,
    sendForm,
  } = useLogin();
  return (
    <Box>
      <GLFormTop
        schema={loginFormValidationSchema}
        values={loginForm}
        onClick={(e) => sendForm(e)}
      >
        {({ errors }) => (
          <GLLoginForm
            showNeedHelp={false}
            loginError={loginErrorMessage}
            onChange={handleChangeLoginForm}
            errors={errorsLoginHookForm(errors)}
            handleNavigateToPrivacyPolicy={handleNavigateToPrivacyPolicy}
            handleNavigateToResetPassword={handleNavigateToResetPassword}
            handleNavigateToTermsOfService={handleNavigateToTermsOfService}
          />
        )}
      </GLFormTop>
    </Box>
  );
};
