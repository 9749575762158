import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

const useAuthService = () => {
  const { http: axiosInstance, getToken } = useAxiosHTTPRequest();

  const headers = { Authorization: getToken() };

  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    return await axiosInstance.post("/login", {
      email,
      password,
    });
  };

  const myAccount = async () => {
    if (!headers.Authorization) return;
    return await axiosInstance.get("/my-account", { headers });
  };

  const logOut = async (id?: string) => {
    return await axiosInstance.post(
      "/logout",
      {},
      {
        headers,
        params: {
          session_id: id,
        },
      }
    );
  };

  return {
    login,
    myAccount,
    logOut,
  };
};

export default useAuthService;
