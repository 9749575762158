/* eslint-disable */

import { useCallback, useEffect, useState } from "react";

import { IDate, IReportData } from "../../services/reportsServices/types";
import useReportsService from "../../services/reportsServices/useReportService";
import useOrganizationService from "../../services/organizationService/useOrganizationService";
import {
  ALLOWED_REPORT_TYPES,
  REPORT_TYPES,
} from "../../utils/constants/const";

type CustomColumns = {
  i18n_from: string;
  i18n_to: string;
}[];

type EnrollCustomColumn = {
  original_column_name: string;
  custom_column_name: string;
}[];

export const useReports = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [data, setData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reportType, setReportType] = useState<string>("");
  const [originalReportColumns, setOriginalReportColumns] = useState<string[]>([
    "",
  ]);
  const [customReportColumns, setCustomReportColumns] = useState<CustomColumns>(
    []
  );
  const [enrollCustomColumns, setEnrollCustomColumns] =
    useState<EnrollCustomColumn>([]);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    createReport,
    deleteReport,
    listReports,
    listStates,
    listCities,
    filterByActivatedAt,
  } = useReportsService();
  const {
    getOriginalReportColumns,
    createCustomColumns,
    getCustomReportColumns,
  } = useOrganizationService();

  const createReportFunction = async (data: IReportData) => {
    let devices_id: string[] | string = "";

    if (data.activation_period) {
      devices_id = await getDevicesIds(data.activation_period);
    }

    return createReport({
      ...data,
      devices_id: devices_id,
    } as IReportData).then((res) => {
      if (res) getReportsFunction();
    });
  };

  const getOriginalReportColumnsFunction = async (reportType: string) => {
    const report = ALLOWED_REPORT_TYPES[reportType];

    const response = await getOriginalReportColumns(report);

    setOriginalReportColumns(response.data);
  };

  const getCustomReportColumnsFunction = async (reportType: string) => {
    const report = REPORT_TYPES[reportType];

    const { data } = await getCustomReportColumns(report);

    if (data.length === 0) return;

    setCustomReportColumns(data);
  };

  const getReportsFunction = useCallback(async (text?: string) => {
    return listReports(text).then((res) => {
      setData(res?.data.rows);
      setIsLoading(false);
    });
  }, []);

  const deleteReportFunction = async (id: string) => {
    return deleteReport(id).then((res) => {
      if (res) getReportsFunction();
    });
  };

  // const getStates = useCallback(async () => {
  //   return listStates().then((res) => setStates(res.data));
  // }, []);

  // const getCitiesByState = async (sigla: string) => {
  //   return listCities(sigla).then((res) => setCities(res.data));
  // };

  const getDevicesIds = async (activationPeriod: IDate): Promise<string[]> => {
    const response = filterByActivatedAt(activationPeriod).then(
      (res) => res.data
    );

    return response;
  };

  const buildPayload = (originalName: string, newName: string) => {
    setEnrollCustomColumns((prevColumns) => {
      const index = prevColumns.findIndex(
        (column) => column.original_column_name === originalName
      );

      if (index !== -1) {
        const newColumns = [...prevColumns];

        newColumns[index] = {
          ...newColumns[index],
          custom_column_name: newName,
        };
        return newColumns;
      } else {
        return [
          ...prevColumns,
          {
            original_column_name: originalName,
            custom_column_name: newName,
          },
        ];
      }
    });
  };

  const createCustomReportColumns = async (): Promise<void> => {
    const report = REPORT_TYPES[reportType];

    const { data, status } = await createCustomColumns({
      report_type: report,
      columns: enrollCustomColumns,
    });

    if (status === 201) {
      setShowAlert(true);
    } else {
      setAlertMessage(data.message);
    }
  };

  async function runGetColumnsRequests(reportType: string) {
    try {
      await Promise.all([
        getOriginalReportColumnsFunction(reportType),
        getCustomReportColumnsFunction(reportType),
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  const handleCloseModal = () => {
    setOpen(!open);
    setOpenModal(!openModal);
  };

  // useEffect(() => {
  //   getStates();
  // }, [getStates]);

  useEffect(() => {
    if (reportType !== undefined && reportType !== "") {
      runGetColumnsRequests(reportType);
    }
  }, [reportType]);

  useEffect(() => {
    if (customReportColumns.length > 0) {
      const mergedArray = originalReportColumns.filter((item) => {
        return !customReportColumns.some((t) => t.i18n_from === item);
      });

      setOriginalReportColumns(mergedArray);
    }
  }, [customReportColumns]);

  useEffect(() => {
    getReportsFunction();
  }, []);

  useEffect(() => {
    setOriginalReportColumns([]);
    setEnrollCustomColumns([]);
    setCustomReportColumns([]);
  }, [reportType]);

  return {
    createReport: createReportFunction,
    deleteReport: deleteReportFunction,
    getReports: getReportsFunction,
    // getCitiesByState,
    data,
    isLoading,
    states,
    cities,
    setReportType,
    reportType,
    originalReportColumns,
    customReportColumns,
    buildPayload,
    createCustomReportColumns,
    openModal,
    setOpenModal,
    open,
    handleCloseModal,
    setOpen,
    showAlert,
    setShowAlert,
    alertMessage,
  };
};
