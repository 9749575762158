import React, { createContext, useCallback, useState } from 'react'


type IMenuMobileContext = {
  isMenuMobileOpen: boolean
  handleOpenMenuMobile: () => void
  closeMenuMobile: () => void
}

type IMenuMobileProviderProps = {
  children: React.ReactNode
}

export const MenuMobileContext = createContext({} as IMenuMobileContext)

export const MenuMobileProvider = ({ children }: IMenuMobileProviderProps) => {
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false)

  const handleOpenMenuMobile = () => {
    setIsMenuMobileOpen(!isMenuMobileOpen)
  }

  const closeMenuMobile = useCallback(() => {
    setIsMenuMobileOpen(false)
  }, [])

  return (
    < MenuMobileContext.Provider
      value={{
        isMenuMobileOpen,
        handleOpenMenuMobile,
        closeMenuMobile
      }}
    >
      {children}
    </  MenuMobileContext.Provider>
  )
}
