import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { GLTheme } from "gl-mui-theme";

import { ThemeProvider as ThemeProviderGLMUI } from "@mui/material/styles";
import { MenuMobileProvider } from "./context/MenuMobileContext.tsx";
import { AuthProvider } from "@grouplink/gl-auth-library";
import { baseURL } from "./conf";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ThemeProviderGLMUI theme={GLTheme}>
    <AuthProvider
      buildGlUser={() => {}}
      loginRedirect={() => true}
      baseURL={baseURL}
    >
      <MenuMobileProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MenuMobileProvider>
    </AuthProvider>
  </ThemeProviderGLMUI>
  // </React.StrictMode>
);
