import React from "react"

import { Box, Skeleton } from "@mui/material"

export function GlMapSkeleton() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        flex: 1,
        height: "100%",
      }}
    >
      <Skeleton variant="rounded" width="80%" height={40} />
      <Skeleton variant="rounded" width="100%" height="100%" />
    </Box>
  )
}
