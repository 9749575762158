import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import { IRewritePassword } from "./types";

const useForgotPasswordServices = () => {
  const { http: axiosInstance, getToken } = useAxiosHTTPRequest();

  const headers = { Authorization: getToken() };

  const sendEmailToResetPassword = async (email: string) => {
    return axiosInstance.post(
      "/reset-password",
      {
        email,
        link_config: "energy",
      },
      { headers }
    );
  };

  const rewritePassword = async (data: IRewritePassword) => {
    return axiosInstance.post("/rewrite-password", data, { headers });
  };

  return { sendEmailToResetPassword, rewritePassword };
};

export default useForgotPasswordServices;
