import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useAllowedPages } from "../hooks/useAllowedPages";
import { Dashboard, useI18n } from "gl-mui-theme";
import "../index.css";
import { Fragment, useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { useAuthenticatedRoutes } from "./useAuthenticatedRoutes";
export const AuthenticatedRoutes = () => {
  const { routes, tabs } = useAuthenticatedRoutes();
  const { userData, signOut } = useAuth();
  const [value, setValue] = useState(0);
  const { allowedPages } = useAllowedPages();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useI18n();
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    switch (pathname) {
      case "/settings/overview":
      case "/devices/overview":
        setValue(0);
        break;

      case "/settings/my-account":
      case "/devices/list":
        setValue(1);
        break;

      case "/settings/security":
        setValue(2);
        break;
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/">
        {allowedPages.map((page) => {
          const { subpages, title } = page;

          return (
            <Fragment key={"temp" + page.path}>
              <Route
                path="/devices"
                element={
                  <Dashboard
                    path={pathname}
                    tabs={routes}
                    user={userData}
                    navigateTo={(el) => navigate(el)}
                    logout={signOut}
                    submenu={
                      <Tabs
                        sx={{ textTransform: "capitalize" }}
                        value={value}
                        onChange={handleChange}
                        aria-label="nav tabs example"
                      >
                        {tabs?.map((element: any) => {
                          return (
                            <Tab
                              sx={{ textTransform: "capitalize" }}
                              key={element.label}
                              label={t(element.label)}
                              onClick={() =>
                                navigate(`/devices${element.path}`)
                              }
                            />
                          );
                        })}
                      </Tabs>
                    }
                  >
                    <Outlet />
                  </Dashboard>
                }
              >
                {subpages &&
                  title === "Devices" &&
                  subpages.map((subpage) => {
                    const { Component, path } = subpage;

                    return (
                      <Route key={path} path={path} element={<Component />} />
                    );
                  })}
              </Route>
              <Route
                path="/settings"
                element={
                  <Dashboard
                    path={pathname}
                    tabs={routes}
                    user={userData}
                    navigateTo={(el) => navigate(el)}
                    logout={signOut}
                    submenu={
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="nav tabs example"
                      >
                        {tabs?.map((element: any) => {
                          return (
                            <Tab
                              sx={{ textTransform: "capitalize" }}
                              key={element.label}
                              label={t(element.label)}
                              onClick={() =>
                                navigate(`/settings${element.path}`)
                              }
                            />
                          );
                        })}
                      </Tabs>
                    }
                  >
                    <Outlet />
                  </Dashboard>
                }
              >
                {subpages &&
                  title === "Settings" &&
                  subpages.map((subpage) => {
                    const { Component, path } = subpage;

                    return (
                      <Route key={path} path={path} element={<Component />} />
                    );
                  })}
              </Route>
              <Route
                path="/reports"
                element={
                  <Dashboard
                    path={pathname}
                    tabs={routes}
                    user={userData}
                    navigateTo={(el) => navigate(el)}
                    logout={signOut}
                    submenu={
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="nav tabs example"
                      >
                        {tabs?.map((element: any) => {
                          return (
                            <Tab
                              key={element.label}
                              label={t(element.label)}
                              onClick={() =>
                                navigate(`/settings${element.path}`)
                              }
                            />
                          );
                        })}
                      </Tabs>
                    }
                  >
                    <Outlet />
                  </Dashboard>
                }
              >
                {subpages &&
                  title !== "Settings" &&
                  title !== "Devices" &&
                  subpages.map((subpage) => {
                    const { Component, path } = subpage;

                    return (
                      <Route key={path} path={path} element={<Component />} />
                    );
                  })}
              </Route>
            </Fragment>
          );
        })}
        <Route path="/" element={<Navigate to="/devices/overview" />} />
        <Route path="*" element={<Navigate to="/devices/overview" />} />
      </Route>
    </Routes>
  );
};
