import { GLPageReports } from "gl-mui-theme";

import { useReports } from "./useReports";

export const Reports = () => {
  const {
    createReport,
    deleteReport,
    getReports,
    data,
    isLoading,
    cities,
    states,
    reportType,
    setReportType,
    originalReportColumns,
    customReportColumns,
    buildPayload,
    createCustomReportColumns,
    handleCloseModal,
    open,
    openModal,
    setOpenModal,
    setOpen,
    alertMessage,
    setShowAlert,
    showAlert,
  } = useReports();

  return (
    <>
      <GLPageReports
        alertMessage={alertMessage}
        setShowAlert={setShowAlert}
        showAlert={showAlert}
        openModal={openModal}
        setOpenModal={setOpenModal}
        open={open}
        setOpen={setOpen}
        handleCloseModal={handleCloseModal}
        buildPayload={buildPayload}
        reportType={reportType}
        setReportType={setReportType}
        data={data}
        isLoading={isLoading}
        cities={cities}
        states={states}
        createReport={createReport}
        deleteReport={deleteReport}
        getReports={getReports}
        appearsTransferList={false}
        left={originalReportColumns}
        right={customReportColumns}
        createCustomReportColumns={createCustomReportColumns}
      />
    </>
  );
};
