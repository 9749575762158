// import { EventsListTable } from "../../../../components/EventsListTable/EventsListTable"
import React from "react";
import { useDeviceList } from "./useDeviceList";

/**
 * depois importarei apenas assim:
 */
import { GLPageDeviceList } from "gl-mui-theme";

export const DevicesList = () => {
  const {
    getDeviceListOrDeviceListFiltered,
    deviceListIsLoading,
    totalDevices,
    bringsDataToChartAfterClickingOnDateTab,
    changeDeviceName,
    handleNextPage,
    getDeviceListBySearch,
  } = useDeviceList();

  return (
    <GLPageDeviceList
      cities={{}}
      states={[{ test: "1" }]}
      neighborhoods={[]}
      total={totalDevices}
      rowsPerPage={6}
      handleLocationFilters={() => {}}
      handleSelectCities={() => {}}
      handleSelectState={() => {}}
      deviceList={getDeviceListOrDeviceListFiltered}
      deviceListIsLoading={deviceListIsLoading}
      bringsDataToChartAfterClickingOnDateTab={
        bringsDataToChartAfterClickingOnDateTab
      }
      changeDeviceName={changeDeviceName}
      handleNextPage={handleNextPage}
      getDeviceListBySearch={getDeviceListBySearch}
    />
  );
};
