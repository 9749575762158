import { useLocation, useNavigate } from "react-router-dom";
import useForgotPasswordServices from "../../services/forgotPassowordServices";

export const useForgotPassword = () => {
  const { sendEmailToResetPassword, rewritePassword } =
    useForgotPasswordServices();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const extractTokenFromPathname = () => {
    const tokenIndex = pathname.lastIndexOf("/");
    const token = pathname.substring(tokenIndex + 1);
    return token;
  };

  const sendEmail = async (data: any) => {
    const { email } = data;
    return await sendEmailToResetPassword(email);
  };

  const rewrite = async (password: string) => {
    const response = await rewritePassword({
      token: extractTokenFromPathname(),
      password,
    });

    if (response.status === 202) navigate("/login");

    return response;
  };

  const handleBackToLogin = () => {
    navigate("/login");
  };

  return {
    sendEmail,
    rewrite,
    handleBackToLogin,
  };
};
