import { useState } from "react";
import { useAuth as useAuthLocal } from "../../../hooks/useAuth";
import { useAuth } from "@grouplink/gl-auth-library";
import useMyAccountService from "../../../services/settingsMyAccountServices";
import { useNavigate } from "react-router-dom";
const FORM_INITIAL_STATE = {
  email: "",
  password: "",
};
export const errorsLoginHookForm = (error: { [x: string]: any }) => {
  const keys = Object.keys(error);
  keys.forEach((key) => {
    if (`${error[key]} is a required field`) {
      return error[key];
    }
  });
  return error;
};

export const errorsLoginGl = (error: string) => {
  if (error === "email| Invalid email or password") {
    return "Incorrect email or password. ";
  }

  return "Your account has been blocked. Please contact support.";
};

export const useLogin = () => {
  const navigate = useNavigate();
  const [acceptCookie, setAcceptCookie] = useState(false);
  const [loginForm, setLoginForm] = useState(FORM_INITIAL_STATE);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | boolean>(
    ""
  );
  const { setSession, verifyUserAPI } = useAuth();
  const { getUser } = useMyAccountService();
  const { setUserData } = useAuthLocal();
  function handleAcceptCookie() {
    setAcceptCookie(true);

    localStorage.setItem("isCookieAccepted", "true");
  }
  function isCookieAccepted() {
    const cookies = localStorage.getItem("isCookieAccepted");

    if (cookies === "true") {
      setAcceptCookie(true);
      return;
    }

    setAcceptCookie(false);
  }

  const handleChangeLoginForm = (property: any, value: any) => {
    const params = { [property]: value };

    setLoginForm((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const handleNavigateToResetPassword = () => {
    navigate("/forgot-password");
  };

  const handleNavigateToTermsOfService = () => {
    window.open("https://dashboard.grouplinknetwork.com/need-help/terms");
  };

  const handleNavigateToPrivacyPolicy = () => {
    window.open("https://dashboard.grouplinknetwork.com/need-help/terms");
  };

  const sendForm = async (e: { preventDefault: () => any }) => {
    e && e.preventDefault();
    await verifyUserAPI({
      email: loginForm.email,
      password: loginForm.password,
    }).then(async (res) => {
      if (res?.session) {
        setSession(`Bearer ${res?.session}`);
        const user = await getUser(`Bearer ${res?.session}`);
        setUserData(user.data.session);
      }
      if (!res?.message) return;
      const { message, error } = res;
      if (error.status !== 200 || error.status !== 401) {
        setLoginErrorMessage(errorsLoginGl(message.message));
        return;
      }

      if (error.status === 400) {
        setLoginErrorMessage(errorsLoginGl(message.messafe));
        return;
      }

      setLoginErrorMessage(false);
    });
  };

  return {
    loginForm,
    acceptCookie,
    loginErrorMessage,
    handleAcceptCookie,
    handleChangeLoginForm,
    handleNavigateToResetPassword,
    handleNavigateToTermsOfService,
    handleNavigateToPrivacyPolicy,
    isCookieAccepted,
    sendForm,
  };
};
