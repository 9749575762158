import { GLBarChart, GLQRCode } from "gl-mui-theme";

export const ROUTES = [
  {
    icon: <GLQRCode />,
    label: "deviceOverview.subtitle",
    link: "/devices",
    value: "devices",
    sidebar: true,
    tab: true,
    submenu: [
      { label: "pages.deviceOverview.overview", path: "/overview" },
      { label: "pages.deviceOverview.list", path: "/list" },
    ],
  },
  {
    icon: <GLBarChart />,
    label: "reports.subtitle",
    link: "/reports",
    tab: false,
    sidebar: true,
    submenu: [],
  },
  {
    icon: null,
    label: "pages.settings.title",
    value: "settings",
    link: "/settings",
    sidebar: false,
    tab: true,
    submenu: [
      { label: "pages.settings.overview", path: "/overview" },
      { label: "pages.settings.myAccount", path: "/my-account" },
      { label: "pages.settings.loginSecurity", path: "/security" },
    ],
  },
];
