/* eslint-disable */

import React, { createContext, useCallback, useEffect, useState } from "react";
import useAuthService from "../services/authService/useAuthService";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@grouplink/gl-auth-library";

type IAuthContext = {
  userDataInSuportMode: any; // TODO
  userData: any; // TODO
  setUserData: any;
  signOut: () => void;
  saveSession: (session: string) => void;
};

type IAuthProviderProps = {
  children: React.ReactNode;
};

export const AuthContext = createContext({} as IAuthContext);

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const navigation = useNavigate();
  const { myAccount } = useAuthService();
  const { logOut, setSession } = useAuth();
  const [session, saveSession] = useLocalStorage<any>("session", null);

  const [userData, setUserData] = useState<any>({
    addresses: [
      {
        customer_id: 0,
        address: "",
        city: "",
        country: "",
        optinal: "",
        state: "",
        zip_code: "",
      },
    ],
    email: "",
    id: 0,
    job_title: "",
    last_name: "",
    name: "",
    org: { id: 0, name: "" },
  });
  const [userDataInSuportMode] = useState<any>({});

  const signOut = async () => {
    await logOut(session);
    setSession("");
    localStorage.clear();
    navigation("/login");
  };

  const updateUser = useCallback(async () => {
    const res = await myAccount();
    if (!res) return;
    const { session } = res.data;
    setUserData({ ...userData, ...session });
  }, [userData]);

  useEffect(() => {
    updateUser();
  }, [session]);

  return (
    <AuthContext.Provider
      value={{
        userDataInSuportMode,
        userData,
        signOut,
        setUserData,
        saveSession: (data) => saveSession(data),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
