import { GLPageLoginSecurity } from "gl-mui-theme";
import { useLoginSecurity } from "./useLoginSecurityServices";
import { IUpdatePasswordData } from "../../../services/settingsLoginSecurityServices/type";
import { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";

export const SettingsLoginSecurity = () => {
  const [token, setToken] = useState("");
  const [sessions, setSessions] = useState();
  const { userData } = useAuth();
  const { sendCode, updatePasswordLogged, getHistorySession, logOutDevice } =
    useLoginSecurity();

  const generateCode = async () => {
    const { email } = userData;
    const response = await sendCode({
      email,
    });

    setToken(response);
    return response;
  };

  const updatePassword = async (data: IUpdatePasswordData) => {
    const response = await updatePasswordLogged({
      token,
      code: data.code,
      password: data.new_password,
    });

    return response;
  };

  const getSessions = () => {
    getHistorySession().then((response) => {
      setSessions(response.data.rows);
    });

    return sessions;
  };

  useEffect(() => {
    getSessions();
    // eslint-disable-next-line
  }, []);

  return (
    <GLPageLoginSecurity
      user={userData}
      sessions={sessions}
      sendCode={generateCode}
      logOutDevice={logOutDevice}
      updatePassword={updatePassword}
    />
  );
};
