import { useDeviceOverview } from "./useDeviceOverview";

// Icons
import { GLPageDeviceOverview } from "gl-mui-theme";

// Styles
import "leaflet/dist/leaflet.css";
import "react-day-picker/dist/style.css";
import { GlMap } from "./components/Map";

type DeviceProps = {
  org_id: number;
  device_id: number;
  activated_at: any;
  channel: number;
  pulse_factor: number;
  pulse_factor_label: string;
  last_measurement: number;
  discount_offset: number;
  device_name: string;
  remote_id: string;
  device_measurement_category: string;
  loc_id: number;
  loc_display_address: string;
  loc_address: string;
  loc_number: string;
  loc_neighborhood: string;
  loc_zipcode: string;
  loc_city: string;
  loc_state: string;
  loc_country: string;
  loc_country_code: string;
  loc_lat: number;
  loc_long: number;
  loc_acc: number;
  gps_id: number;
  gps_display_address: string;
  gps_address: string;
  gps_number: string;
  gps_neighborhood: string;
  gps_zipcode: string;
  gps_city: string;
  gps_state: string;
  gps_country: string;
  gps_country_code: string;
  gps_lat: number;
  gps_long: number;
  gps_acc: number;
};

export type DeviceListProps = DeviceProps[];

export const DevicesOverview = () => {
  const {
    map,
    cards,
    transmissionFrequency,
    isLoading,
    times,
    dataSelect,
    defaultDate,
    request,
    onSelectStartDate,
    handleTimesSelect,
    options,
    handleOptions,
    totalDevices,
  } = useDeviceOverview();

  const handleInstantReload = async () => {
    return await request();
  };

  return (
    <GLPageDeviceOverview
      totalDevices={totalDevices}
      map={() => <GlMap deviceList={map} isLoading={isLoading} />}
      cards={cards}
      transmission={transmissionFrequency}
      dataSelect={dataSelect}
      instantReload={handleInstantReload}
      handleApplyFilter={handleTimesSelect}
      onSelectStartDate={(e: any) => onSelectStartDate(e)}
      startDate={defaultDate ? new Date(defaultDate) : new Date()}
      neighborhoods={[]}
      times={times}
      optionsFilters={options?.stateCities}
      handleSelectOptionsFilters={handleOptions}
      isLoading={isLoading}
    />
  );
};
