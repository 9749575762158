/* eslint-disable */
import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import {
  CARDS,
  FIVE_MINUTES_IN_MILLISECONDS,
  IS_LOADING,
  MAP,
  OFF,
  ONE_MINUTE_IN_MILLISECONDS,
  TEN_MINUTES_IN_MILLISECONDS,
  TRANSMISSION_FREQUENCY,
} from "./utils/defaultValues";
import useDeviceOverviewService from "../../../services/deviceOvervireService/useDeviceOverviewService";

interface IRangeDateSelected {
  start_date: string;
  interval: number;
}
const start_date = format(new Date(), "yyyy-MM-dd");
const useDeviceOverview = () => {
  const [rangeDateSelected, setRangeDateSelected] =
    useState<IRangeDateSelected>({
      start_date,
      interval: 1,
    });
  const [intervalCode, setIntervalCode] = useState<NodeJS.Timer>();
  const [selectInterval, setSelectInterval] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [devices, setDevices] = useState();
  const [isLoading, setIsLoading] = useState(IS_LOADING);
  const [map, setMap] = useState(MAP);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [options, setOptions] = useState<any>({});
  const [locations, setLocations] = useState<any>({});
  const [cards, setCards] = useState<any[]>(CARDS);
  const [transmissionFrequency, setTransmissionFrequency] = useState(
    TRANSMISSION_FREQUENCY
  );
  const {
    readsBetween,
    totalReads,
    gettransmissionFrequency,
    totalDevices,
    mapByOrgId,
    getLocation,
    filtersLocation,
  } = useDeviceOverviewService();

  const getNoDeviceRead = async (value: number) => {
    return {
      percent: 20,
      title: "Dispositivo sem leitura",
      value: value,
      variant: "tertiary",
    };
  };

  const request = useCallback(
    async (type = "request") => {
      type === "start" && setIsLoading(true);
      getLocation()
        .then((res) => setOptions(res))
        .catch((err) => console.error("ERR getLocation: ", err));

      try {
        const um = await totalDevices(rangeDateSelected);
        const dois = await totalReads(rangeDateSelected, locations);
        setDevices(um.value);

        const tres = await getNoDeviceRead(um.value - dois.value);

        const quatro = await readsBetween(rangeDateSelected);
        const map = await mapByOrgId();
        const transmission = await gettransmissionFrequency(
          rangeDateSelected,
          locations
        );
        setTransmissionFrequency(transmission);
        setMap(map);
        setCards([um, dois, tres, quatro]);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
    [rangeDateSelected, locations]
  );

  const handleSelectTimer = (value: number) => {
    clearInterval(intervalCode);
    if (value === 0) return;

    const code = setInterval(() => {
      const timer = new Date();
      console.log(
        "DATA: ",
        `${timer.getHours()}:${timer.getMinutes()}:${timer.getSeconds()}`
      );
    }, value);

    setIntervalCode(code);
  };

  const dataSelect = [
    {
      title: "off",
      onSelect: () => {
        handleSelectTimer(OFF);
      },
    },
    {
      title: "1 min",
      onSelect: () => {
        handleSelectTimer(ONE_MINUTE_IN_MILLISECONDS);
      },
    },
    {
      title: "5 min",
      onSelect: () => {
        handleSelectTimer(FIVE_MINUTES_IN_MILLISECONDS);
      },
    },
    {
      title: "10 min",
      onSelect: () => {
        handleSelectTimer(TEN_MINUTES_IN_MILLISECONDS);
      },
    },
  ];

  const handleTimesSelect = () => {
    setRangeDateSelected({
      interval: selectInterval,
      start_date: startDate,
    });
  };

  useEffect(() => {
    request();
  }, [rangeDateSelected]);

  const times = [
    {
      onSelect: () => {
        setSelectInterval(1);
      },
      title: "Last 1 day",
    },
    {
      onSelect: () => {
        setSelectInterval(7);
      },
      title: "Last 7 days",
    },
    {
      onSelect: () => {
        setSelectInterval(15);
      },
      title: "Last 15 days",
    },
    {
      onSelect: () => {
        setSelectInterval(30);
      },
      title: "Last 30 days",
    },
  ];

  const onSelectStartDate = (value: Date) => {
    // Cria um objeto Date a partir da string de data fornecida
    const dataObjeto = new Date(value);

    // Verifica se a data é válida
    if (isNaN(dataObjeto.getTime())) {
      return "Data inválida";
    }

    // Configuração para o formato desejado
    const formatoDesejado = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Formata a data
    const [{ value: mes }, , { value: dia }, , { value: ano }] =
      formatoDesejado.formatToParts(dataObjeto);

    // Monta a string no formato desejado
    const dataFormatada = `${ano}-${mes}-${dia}`;
    setDefaultDate(value);
    setStartDate(dataFormatada);
  };

  const handleLocationFilters = async () => {
    await filtersLocation(city, state);
  };
  const handleSelectCities = (value: string) => {
    setCity(value);
  };
  const handleSelectState = (value: string) => {
    setState(value);
  };

  const handleOptions = (data: any) => {
    setLocations(data);
  };

  return {
    map,
    cards,
    transmissionFrequency,
    isLoading,
    times,
    dataSelect,
    location,
    defaultDate,
    request,
    onSelectStartDate,
    handleTimesSelect,
    handleLocationFilters,
    handleSelectState,
    handleSelectCities,
    options,
    handleOptions,
    totalDevices: devices,
  };
};

export { useDeviceOverview };
