import { GLPageMyAccount } from "gl-mui-theme";
import { useMyAccount } from "./useMyAccountServices";
import { useAuth } from "../../../hooks/useAuth";

export const SettingsMyAccount = () => {
  const { updateUserInfo } = useMyAccount();
  const { userData } = useAuth();

  const updateUser = async (data: any) => {
    const response = await updateUserInfo(data).catch((err) =>
      console.log(err)
    );

    return response;
  };

  return (
    <>
      <GLPageMyAccount data={userData} updateUser={updateUser} />
    </>
  );
};
