import { IData } from "./types";
import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

const useDeviceOverviewService = () => {
  const { http: axiosInstance, getToken } = useAxiosHTTPRequest();

  const headers = { Authorization: getToken() };

  const readsBetween = async (data: IData) => {
    const { start_date, interval } = data;
    const { data: readsBetween } = await axiosInstance.get(
      `device/reads-between-dates?interval=${interval}&start_date=${start_date}`,
      { headers }
    );
    return {
      percent: 10,
      title: "Taxa de comunicação",
      value: `${readsBetween.data || 0}%`,
      variant: "quaternary",
    };
  };

  const totalReads = async (
    data: IData,
    options?: { state: string; city: string }
  ) => {
    const { start_date, interval } = data;
    const {
      data: { response },
    } = await axiosInstance.get(
      `/device/total-reads?interval=${interval}&start_date=${start_date}${
        options?.state ? "&state=" + options.state : ""
      }${options?.city ? "&city=" + options.city : ""}`,
      {
        headers,
      }
    );
    return {
      percent: 0,
      title: "Dispositivo com leitura",
      value: response.length || 0,
      variant: "secondary",
    };
  };

  const gettransmissionFrequency = async (
    data: IData,
    options?: { state: string; city: string }
  ) => {
    const { start_date, interval } = data;

    const { data: response } = await axiosInstance.get(
      `/device/transmission-frequency?interval=${interval}&start_date=${start_date}${
        options?.state ? "&state=" + options.state : ""
      }${options?.city ? "&city=" + options.city : ""}`,
      {
        headers,
      }
    );

    return {
      percentage_not_streamed: response.data.percentage_transmited,
      percentage_streamed: response.data.percentage_not_transmited,
      total_devices: response.data.total_devices,
    };
  };

  const totalDevices = async (data: IData) => {
    const { start_date, interval } = data;
    const {
      data: { response },
    } = await axiosInstance.get(
      `/device/total-devices?interval=${interval}&start_date=${start_date}`,
      { headers }
    );
    return {
      percent: 20,
      title: "Total de dispositivos",
      value: response || 0,
      variant: "primary",
    };
  };

  const mapByOrgId = async () => {
    const {
      data: { response },
    } = await axiosInstance.get(`/device/map-device-by-org`, {
      headers,
    });
    return response;
  };

  const getLocation = async () => {
    const {
      data: { response },
    } = await axiosInstance.get(`/device/city-state`, {
      headers,
    });

    return response;
  };
  const filtersLocation = async (city: string, state: string) => {
    const {
      data: { response },
    } = await axiosInstance.get(
      `filter-device-city-state?city=${city}&state=${state}`,
      {
        headers,
      }
    );
    return response;
  };

  return {
    readsBetween,
    totalReads,
    gettransmissionFrequency,
    totalDevices,
    mapByOrgId,
    getLocation,
    filtersLocation,
  };
};

export default useDeviceOverviewService;
