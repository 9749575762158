import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { ShowDevices } from "./ShowDevices/ShowDevices";

import { Box } from "@mui/material";
import { GlMapSkeleton } from "./gl-map-skeleton";

const defaultPosition: PositionProps = { lat: -23.5489, lng: -46.6388 };

export type PositionProps = {
  lat: number;
  lng: number;
};

export type DevicesMapProps = {
  deviceList: any[];
  isLoading?: boolean;
};

export function GlMap({ deviceList, isLoading }: DevicesMapProps) {
  if (isLoading) {
    return <GlMapSkeleton />;
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "250px",
        "& #map": {
          height: "100px",
          width: "100px",
          borderRadius: "10px",
          minHeight: "250px",
          zIndex: 10,
          ".leaflet-marker-icon": {
            background: "transparent !important",
            border: "none !important",
          },
          ".cluster-marker": {
            color: "#fff",
            background: "#0099ff",
            font: "16px",
            borderRadius: "50%",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      }}
    >
      <MapContainer
        id="map"
        style={{ width: "100%", height: "100%" }}
        center={defaultPosition}
        zoom={10}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <ShowDevices deviceList={deviceList} />
      </MapContainer>
    </Box>
  );
}
