import useAuthService from "../../../services/authService/useAuthService";
import {
  IData,
  IUpdatePasswordRequest,
} from "../../../services/settingsLoginSecurityServices/type";
import useLoginSecurityService from "../../../services/settingsLoginSecurityServices/useLoginSecurityService";

const useLoginSecurity = () => {
  const { securityCode, updatePasswordLogged, getHistory } =
    useLoginSecurityService();
  const { logOut } = useAuthService();

  const sendCode = async (body: IData) => {
    const { data } = await securityCode(body);

    return data;
  };

  const updatePasswordLoggedFunction = async (body: IUpdatePasswordRequest) => {
    return await updatePasswordLogged(body);
  };

  const getHistorySession = async () => {
    return getHistory();
  };

  const logOutDevice = async (id: string) => {
    await logOut(id);
  };
  return {
    sendCode,
    updatePasswordLogged: updatePasswordLoggedFunction,
    getHistorySession,
    logOutDevice,
  };
};

export { useLoginSecurity };
