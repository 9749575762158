import { useMemo } from "react";

import { GLBarChart, GLQRCode, GLSettings } from "gl-mui-theme";
import { DevicesOverview } from "../pages/Devices/Overview/DevicesOverview";
import { DevicesList } from "../pages/Devices/List/DevicesList";
import { SettingsOverview } from "../pages/Settings/Overview";
import { SettingsMyAccount } from "../pages/Settings/MyAccount";
import { SettingsLoginSecurity } from "../pages/Settings/LoginSecurity";
import { Reports } from "../pages/Reports";

export const useAllowedPages = () => {
  const userRole = "user";

  const pages = useMemo(
    () => [
      {
        title: "Devices",
        path: "/devices",
        Icon: GLQRCode,
        allowedRoles: ["admin", "user"],
        subpages: [
          {
            id: 1,
            title: "Overview",
            path: "/devices/overview",
            allowedRoles: ["admin", "user"],
            Component: DevicesOverview,
          },
          {
            id: 2,
            title: "List",
            path: "/devices/list",
            allowedRoles: ["admin", "user"],
            Component: DevicesList,
          },
        ],
      },
      {
        title: "Reports",
        path: "/reports",
        Icon: GLBarChart,
        allowedRoles: ["admin", "user"],
        subpages: [
          {
            id: 1,
            title: "Reports",
            path: "/reports",
            allowedRoles: ["admin", "user"],
            Component: Reports,
          },
        ],
      },
      {
        title: "Settings",
        path: "/settings",
        Icon: GLSettings,
        allowedRoles: ["admin", "user"],
        subpages: [
          {
            id: 1,
            title: "Overview",
            path: "/settings/overview",
            allowedRoles: ["admin", "user"],
            Component: SettingsOverview,
          },
          {
            id: 2,
            title: "My Account",
            path: "/settings/my-account",
            allowedRoles: ["admin", "user"],
            Component: SettingsMyAccount,
          },
          {
            id: 3,
            title: "Login & Security",
            path: "/settings/security",
            allowedRoles: ["admin", "user"],
            Component: SettingsLoginSecurity,
          },
        ],
      },
    ],
    []
  );

  const allowedPages = useMemo(() => {
    return pages
      .filter((page) => {
        if (page.allowedRoles.includes(userRole)) return page;

        return page;
      })
      .map((page) => {
        if (page.subpages) {
          const subpages = page.subpages.filter((subpage) => {
            if (subpage.allowedRoles.includes(userRole)) return subpage;

            return subpage;
          });
          return { ...page, subpages };
        }
        return page;
      });
  }, [pages, userRole]);

  return { allowedPages };
};
