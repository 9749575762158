import {
  GetDeviceCountAllResponse,
  GetDeviceDataParams,
  GetDeviceDataResponse,
  GetDeviceListParams,
  GetDeviceListResponse,
  UpdateDeviceNameParams,
} from "./types";
import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useDeviceListService = () => {
  const { http: axiosInstance, getToken } = useAxiosHTTPRequest();

  const headers = { Authorization: getToken() };

  const updateDeviceName = async (
    data: UpdateDeviceNameParams
  ): Promise<void> => {
    const { channel_data, device_id } = data;

    const response = await axiosInstance.patch(
      `/device-name`,
      {
        device_id,
        channel_data,
      },
      {
        headers,
      }
    );

    return response.data;
  };

  const getDeviceData = async (
    data: GetDeviceDataParams
  ): Promise<GetDeviceDataResponse> => {
    const { device_id, from, to } = data;

    const response = await axiosInstance.get(`/device-data`, {
      params: {
        device_id,
        from,
        to,
      },
      headers,
    });

    return response.data;
  };

  const getDeviceList = async (
    data: GetDeviceListParams,
    filteredData?: any
  ): Promise<GetDeviceListResponse> => {
    const { next_page_token, ipp } = data;

    const queryParams = filteredData
      ?.map((id: string) => `device_id=${id}`)
      .join("&");

    console.log(queryParams);

    const response = await axiosInstance.get(
      `/devices${filteredData ? `?${queryParams}` : ""}`,
      {
        params: {
          next_page_token,
          ipp,
        },
        headers,
      }
    );

    return response.data;
  };

  const getDeviceCountAll = async (): Promise<GetDeviceCountAllResponse> => {
    const response = await axiosInstance.get(`/devices/count`, {
      headers,
    });
    return response.data;
  };

  const getDeviceFilterByName = async (
    text: string
  ): Promise<GetDeviceListResponse> => {
    const response = await axiosInstance.get(`/filter-device-by-name`, {
      params: {
        text,
      },
      headers,
    });
    return response.data;
  };

  return {
    updateDeviceName,
    getDeviceData,
    getDeviceListService: getDeviceList,
    getDeviceCountAll,
    getDeviceFilterByName,
  };
};
