import useMyAccountService from "../../../services/settingsMyAccountServices";
import { IData } from "../../../services/settingsMyAccountServices/type";

const useMyAccount = () => {
  const { updateUser } = useMyAccountService();
  const updateUserInfo = async (data: IData) => {
    return await updateUser(data);
  };

  return {
    updateUserInfo,
  };
};

export { useMyAccount };
