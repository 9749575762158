import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import { IData } from "./type";

const useMyAccountService = () => {
  const { http: axiosInstance, getToken } = useAxiosHTTPRequest();

  const headers = { Authorization: getToken() };

  const updateUser = async (data: IData) => {
    return axiosInstance.patch("/my-account", data, {
      headers,
    });
  };
  const getUser = async (session: string) => {
    return await axiosInstance.get("/my-account", {
      headers: { Authorization: getToken() || session },
    });
  };
  return {
    updateUser,
    getUser,
  };
};

export default useMyAccountService;
