import { useEffect, useState } from "react";
import { ROUTES } from "./utils/routes";
import { useLocation } from "react-router-dom";

export const useAuthenticatedRoutes = () => {
  const [tabs, setTabs] = useState<any>([{}]);
  const { pathname } = useLocation();
  const routes = ROUTES;

  const getTabs = (url: string) => {
    let listTabs: any = [];
    if (url.includes("devices")) {
      routes.forEach(({ submenu, tab, label }) => {
        if (tab && label === "deviceOverview.subtitle") {
          listTabs = submenu;
        }
      });
    } else if (url.includes("settings")) {
      routes.forEach(({ submenu, tab, label }) => {
        if (tab && label === "pages.settings.title") listTabs = submenu;
      });
    }
    setTabs(listTabs);
  };
  useEffect(() => {
    getTabs(pathname);
    // eslint-disable-next-line
  }, [pathname]);

  return { routes, tabs };
};
