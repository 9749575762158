import { GLPageOverview } from "gl-mui-theme";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";

export const SettingsOverview = () => {
  const navigate = useNavigate();
  const { userData } = useAuth();
  // const user = {
  //   addresses: [
  //     {
  //       customer_id: 143,
  //       address: "rua quintana",
  //       city: "MT",
  //       country: "Brazil",
  //       optinal: "09019302178",
  //       state: "Ceará",
  //       zip_code: "02209000",
  //     },
  //   ],
  //   email: "j.silva@grouplinknetwork.com",
  //   id: 143,
  //   job_title: "DEV",
  //   last_name: "Silva",
  //   name: "João",
  //   org: { id: 225, name: "GL Toten" },
  // };
  return (
    <GLPageOverview
      lastUpdateContent={() => {}}
      user={userData}
      editProfileButton={() => navigate("/settings/my-account")}
      accessHistoryRedirect={() => navigate("/settings/security")}
      updatePasswordRedirect={() => navigate("/settings/security")}
    />
  );
};
