import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import { CreateCustomReportColumns } from "./types";

const useOrganizationService = () => {
  const { http: axiosInstance, getToken } = useAxiosHTTPRequest();

  const headers = { Authorization: getToken() };

  const getOriginalReportColumns = async (reportType: string) => {
    return axiosInstance.get(`/reports/original-columns`, {
      headers,
      params: { report_type: reportType },
    });
  };

  const getCustomReportColumns = async (reportType: string) => {
    return axiosInstance.get(`/organization/custom-report-columns`, {
      headers,
      params: { report_type: reportType },
    });
  };

  const createCustomColumns = async (data: CreateCustomReportColumns) => {
    return axiosInstance.post(
      `/organization/enroll-custom-report-columns`,
      data,
      {
        headers,
      }
    );
  };

  return {
    getOriginalReportColumns,
    createCustomColumns,
    getCustomReportColumns,
  };
};

export default useOrganizationService;
