import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Onboarding } from "gl-mui-theme";
import { Login } from "../pages/Auth/Login";
import "../index.css";
import { Box } from "@mui/material";
import { ForgotPassword } from "../pages/ForgotPassword/SendEmail";
import { CreatePassoword } from "../pages/ForgotPassword/CreatePassword";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Box>
            <Onboarding showNeedHelp={false}>
              <>
                <Outlet />
              </>
            </Onboarding>
          </Box>
        }
      >
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Route>
      <Route
        path="/forgot-password"
        element={
          <Box>
            <Onboarding
              handleClickNeedHelp={() =>
                window.open("https://dashboard.grouplinknetwork.com/need-help")
              }
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Outlet />
              </Box>
            </Onboarding>
          </Box>
        }
      >
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>

      <Route
        path="/new-password/:token"
        element={
          <Box>
            <Onboarding
              handleClickNeedHelp={() =>
                window.open("https://dashboard.grouplinknetwork.com/need-help")
              }
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Outlet />
              </Box>
            </Onboarding>
          </Box>
        }
      >
        <Route path="/new-password/:token" element={<CreatePassoword />} />
      </Route>
    </Routes>
  );
};
