import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { SeriesColumnOptions } from "highcharts";
import {
  IDeviceResult,
  IHandleChangeSelectedDate,
  IChangeDeviceNameParams,
} from "gl-mui-theme";
import { useDeviceListService } from "../../../services/deviceListService/index";

const TOTAL_ITEMS_TO_APPEAR = 6;

export const useDeviceList = () => {
  const {
    getDeviceCountAll,
    getDeviceData,
    getDeviceFilterByName,
    getDeviceListService,
    updateDeviceName,
  } = useDeviceListService();

  const [totalDevices, setTotalDevices] = useState<any>(0);
  const [deviceListIsLoading, setDeviceListIsLoading] = useState<boolean>(true);
  const [deviceList, setDeviceList] = useState<IDeviceResult[]>([]);
  const [deviceListFiltered, setDeviceListFiltered] = useState<IDeviceResult[]>(
    []
  );

  const [nextPageToken, setNextPageToken] = useState<string | null | undefined>(
    undefined
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [showingCards, setShowingCards] = useState({
    from: 0,
    to: 0,
  });

  const getDeviceListOrDeviceListFiltered: IDeviceResult[] = useMemo(() => {
    return deviceListFiltered?.length > 0 ? deviceListFiltered : deviceList;
  }, [deviceList, deviceListFiltered]);

  const updateDeviceList = useCallback(
    async (options: IChangeDeviceNameParams) => {
      setDeviceList((deviceList) => {
        return deviceList.map((device) => {
          if (device.device_id === options.device_id) {
            return {
              ...device,
              channels: device.channels.map((channel) => {
                if (channel.channel === options.channel_data[0].channel) {
                  return {
                    ...channel,
                    name: options.channel_data[0].channel_name,
                  };
                }
                return channel;
              }),
            };
          }

          return device;
        });
      });
    },
    []
  );

  const changeDeviceName = useCallback(
    async (options: IChangeDeviceNameParams) => {
      const { channel_data } = options;

      if (getDeviceListOrDeviceListFiltered.length === 0) return;

      await updateDeviceName(options);

      if (deviceListFiltered.length > 0) {
        setDeviceListFiltered((deviceList) => {
          return deviceList.map((device) => {
            if (device.device_id === options.device_id) {
              return {
                ...device,
                channels: device.channels.map((channel) => {
                  if (channel.channel === channel_data[0].channel) {
                    return {
                      ...channel,
                      name: channel_data[0].channel_name,
                    };
                  }
                  return channel;
                }),
              };
            }

            return device;
          });
        });

        updateDeviceList(options);
      } else {
        updateDeviceList(options);
      }
    },
    // eslint-disable-next-line
    [
      getDeviceListOrDeviceListFiltered,
      deviceListFiltered?.length,
      updateDeviceList,
    ]
  );

  const scrollWindowToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 1000);
  };

  const bringsDataToChartAfterClickingOnDateTab = useCallback(
    async (options: IHandleChangeSelectedDate) => {
      const { from, to } = options;
      const days = dayjs(to).diff(dayjs(from), "day");
      const arrayDays = Array.from({ length: days }, (_, i) => {
        return dayjs(from).add(i, "day").format("MMM DD YYYY");
      });

      try {
        const data = await getDeviceData(options);

        if (data.rows.length === 0) {
          return {
            series: [],
            categories: [],
          };
        }

        const dataChartFormatted = data.rows.map((row) => {
          return {
            ...row,
            time: dayjs(row.time).format("MMM DD YYYY"),
          };
        });

        const series: SeriesColumnOptions["data"] = arrayDays.map((day) => {
          const readingValue =
            dataChartFormatted.find((data) => data.time === day)?.reading || 0;

          return {
            y: readingValue,
            // color: theme.palette.primary.main,
          };
        });
        const categories = [...arrayDays];

        return { series, categories };
      } catch (err) {
        return {
          series: [],
          categories: [],
        };
      }
    },
    // eslint-disable-next-line
    []
  );

  const getDeviceList = useCallback(
    async (
      next_page_token?: string,
      isToUpdateDeviceList?: boolean
    ): Promise<IDeviceResult[] | []> => {
      try {
        setDeviceListIsLoading(true);
        const options = {
          next_page_token,
          ipp: TOTAL_ITEMS_TO_APPEAR,
        };

        const data = await getDeviceListService(options, undefined);

        if (isToUpdateDeviceList) {
          setDeviceList((prev) => [...prev, ...data.rows]);
        } else {
          setDeviceList(data.rows);
        }

        if (data.next_page_token) {
          setNextPageToken(data.next_page_token);
        } else {
          setNextPageToken(null);
        }

        return data.rows;
      } catch (err) {
        return [];
      } finally {
        setDeviceListIsLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  const getTotalDeviceList = useCallback(async () => {
    const num = await getDeviceCountAll();
    setTotalDevices(num?.count || 0);

    // eslint-disable-next-line
  }, []);

  const getDeviceListBySearch = useCallback(async (device_name: string) => {
    try {
      if (device_name.length === 0) {
        setDeviceListFiltered([]);
      } else {
        const data = await getDeviceFilterByName(device_name);

        const filteredData = await getDeviceListService(
          {
            next_page_token: undefined,
            ipp: 6,
          },
          data
        );

        setDeviceListFiltered(filteredData.rows);
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line
  }, []);

  const handleNextPage = useCallback(async () => {
    const nextCardsAlreadyExists = deviceList.length > showingCards.to;

    if (deviceListFiltered.length > 0) {
      setCurrentPage((prev) => prev + 1);
    } else {
      if (nextPageToken && !nextCardsAlreadyExists) {
        await getDeviceList(nextPageToken, true);
        setCurrentPage((prev) => prev + 1);
      } else {
        setCurrentPage((prev) => prev + 1);
      }
    }

    scrollWindowToTop();
  }, [
    deviceList,
    getDeviceList,
    nextPageToken,
    showingCards.to,
    deviceListFiltered,
  ]);

  useEffect(() => {
    async function resetAndFetchDeviceList() {
      await Promise.all([getTotalDeviceList(), getDeviceList()]);
    }

    resetAndFetchDeviceList();
  }, [getDeviceList, getTotalDeviceList]);

  useEffect(() => {
    if (deviceListFiltered?.length > 0) {
      let from = currentPage * TOTAL_ITEMS_TO_APPEAR;
      let to = (currentPage + 1) * TOTAL_ITEMS_TO_APPEAR;

      if (to >= deviceListFiltered.length) {
        to = deviceListFiltered.length;
      } else {
        to = (currentPage + 1) * TOTAL_ITEMS_TO_APPEAR;
      }

      if (from + 1 > deviceListFiltered.length) {
        setCurrentPage(0);
      }

      setShowingCards({ from, to });
    } else {
      if (nextPageToken) {
        const from = currentPage * TOTAL_ITEMS_TO_APPEAR;
        const to = (currentPage + 1) * TOTAL_ITEMS_TO_APPEAR;

        setShowingCards({ from, to });
      } else if (nextPageToken === null) {
        const from = currentPage * TOTAL_ITEMS_TO_APPEAR;
        let to = (currentPage + 1) * TOTAL_ITEMS_TO_APPEAR;

        if (to >= deviceList.length) {
          to = deviceList.length;
        } else {
          to = (currentPage + 1) * TOTAL_ITEMS_TO_APPEAR;
        }

        setShowingCards({ from, to });
      } else {
        const from = currentPage * TOTAL_ITEMS_TO_APPEAR;
        const to = deviceList.length;

        setShowingCards({ from, to });
      }
    }
  }, [
    currentPage,
    deviceList?.length,
    nextPageToken,
    deviceListFiltered?.length,
  ]);

  return {
    getDeviceListOrDeviceListFiltered,
    deviceListIsLoading,
    totalDevices,
    bringsDataToChartAfterClickingOnDateTab,
    changeDeviceName,
    handleNextPage,
    getDeviceListBySearch,
  };
};
