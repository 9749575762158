export const OFF = 0;
export const ONE_MINUTE_IN_MILLISECONDS = 60000;
export const FIVE_MINUTES_IN_MILLISECONDS = 300000;
export const TEN_MINUTES_IN_MILLISECONDS = 600000;

export const IS_LOADING = true;
export const MAP = [];
export const TOTAL_READS = {};
export const NO_DEVICES_READS = {};
export const TRANSMISSION_FREQUENCY = {
  percentage_not_streamed: 54.35897435897436,
  percentage_streamed: 45.64102564102564,
  total_devices: 39,
};

export const CARDS = [
  TOTAL_READS,
  TOTAL_READS,
  NO_DEVICES_READS,
  NO_DEVICES_READS,
];
