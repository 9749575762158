import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import moment from "moment";
import { IDate, IReportData } from "./types";

const useReportsService = () => {
  const { http: axiosInstance, getToken } = useAxiosHTTPRequest();

  const headers = { Authorization: getToken() };

  const createReport = async (data: IReportData) => {
    return axiosInstance.post("/reports", data, { headers });
  };

  const listReports = async (name?: string) => {
    return axiosInstance.get("/reports", { headers, params: { name } });
  };

  const deleteReport = async (id: string) => {
    return axiosInstance.delete(`/reports/${id}`, { headers });
  };

  const listStates = async () => {
    return axiosInstance.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados",
    );
  };

  const filterByActivatedAt = async (datas: IDate) => {
    const { from, until } = datas;

    return axiosInstance.get(`filter-device-by-name`, {
      headers,
      params: {
        activated_at_since: moment(from)?.format("YYYY-MM-DD"),
        activated_at_until: moment(until)?.format("YYYY-MM-DD"),
      },
    });
  };

  const listCities = async (sigla: string) => {
    return axiosInstance.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/distritos`,
    );
  };
  return {
    createReport,
    listReports,
    deleteReport,
    listStates,
    listCities,
    filterByActivatedAt,
  };
};

export default useReportsService;
