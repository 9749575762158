import { Fragment } from "react";
import { AuthenticatedRoutes } from "./authenticate.routes";
import { AuthRoutes } from "./auth.routes";
import { useAuth } from "@grouplink/gl-auth-library";
export const Routes = () => {
  const { session } = useAuth();
  return (
    <Fragment>{session ? <AuthenticatedRoutes /> : <AuthRoutes />}</Fragment>
  );
};
