import axios from "axios";

import { baseURL } from "../conf";

const apiInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const useAxiosHTTPRequest = () => {
  const getToken = () => {
    if (
      localStorage.getItem("session") !== "undefined" &&
      Boolean(localStorage.getItem("session"))
    ) {
      const session = JSON.parse(localStorage.getItem("session") as string);
      return session;
    }
    return null;
  };

  return {
    http: apiInstance,
    getToken,
  };
};

export default useAxiosHTTPRequest;
