import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import { IData, IUpdatePasswordRequest } from "./type";

const useLoginSecurityService = () => {
  const { http: axiosInstance, getToken } = useAxiosHTTPRequest();

  const securityCode = async (body: IData) => {
    return await axiosInstance.post("/send-code", body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    });
  };

  const updatePasswordLogged = async (body: IUpdatePasswordRequest) => {
    return await axiosInstance.patch("/reset-password-logged", body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    });
  };
  const getHistory = async () => {
    return axiosInstance.get("/sessions", {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    });
  };

  return {
    securityCode,
    updatePasswordLogged,
    getHistory,
  };
};

export default useLoginSecurityService;
