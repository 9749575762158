export const ALLOWED_REPORT_TYPES: any = {
  EventListRange: "full_water",
  DeviceList: "device_list_snapshot",
  DeviceListLatest: "latest_water_report",
};

export const REPORT_TYPES: any = {
  EventListRange: "full_water",
  DeviceList: "device_list_snapshot",
  DeviceListLatest: "latest",
};
