import { GLResetPasswordForm } from "gl-mui-theme";
import { useForgotPassword } from "../useForgotPassword";

export const ForgotPassword = () => {
  const { handleBackToLogin, sendEmail } = useForgotPassword();

  return (
    <GLResetPasswordForm
      sendEmail={sendEmail}
      handleClickBackToLogin={handleBackToLogin}
    />
  );
};
